.navbarEdit {
  background-color: #1a1a1e;
}

.ajustCenter {
  max-width: 950px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 28px;
  color: #ffffff;
}

h2 {
  color: #c0c0c0;
}

h3 {
  color: #c0c0c0;
}

h4 {
  color: #a9a9a9;
}

h5 {
  color: #a9a9a9;
}

h6 {
  color: #696969;
}

p {
  color: #808080;
}

.h1form {
  color: #ffffff;
}

.label {
  color: #d3d3d3;
  font-size: 30px;
}

.containervar {
  margin-top: 20px;
}

form {
  border-radius: 20px;
}

.divfooter {
  padding-right: 10px;
  padding-left: 10px;
}

footer {
  background: #1a1a1e;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  margin: 0 auto !important;
}

footer ul {
  display: table;
}

.textColorFooter {
  color: #808080;
}

.divalerttop {
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .divalerttop {
    margin-top: 35%;
  }
}

@media only screen and (min-width: 769px) {
  .divalerttop {
    margin-top: 15%;
  }
}

.alertprincipal {
  padding-right: 20px;
  padding-left: 20px;
}

.alertfooter {
  margin-right: 20px;
  margin-left: 20px;
}

.sociaismidian {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.divform {
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.formpricipal {
  margin-top: 20px;
  margin-bottom: 25px;
  padding-top: 20px;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 20px;
}

.bottomconfirm {
  justify-content: center;
  align-items: center;
}

#msg {
  margin-top: 20px;
}

.divform-informations {
  padding-right: 10px;
  padding-left: 10px;
}

.aboutus {
  padding-top: 100px;
  padding-right: 20px;
  padding-left: 20px;
}

.sociaismidian {
  padding-right: 50px;
  padding-left: 50px;
}

.btnfooter {
  min-width: 35%;
  overflow: hidden;
}

.link-btn {
  color: inherit;
  text-decoration: none;
}

.link-btn:hover {
  color: black;
}

.mycarousel {
  background: black;
  margin-bottom: 20px;
}

.carouselimg {
  content: "";
  opacity: 0.8;
  max-height: 320px;
  min-height: 300px;
}

.slidetext {
  max-width: 100%;
  max-height: 100%;
  color: white;
}

.item-image {
  max-width: 420px;
  min-height: 200px;
  min-width: 400px;
  padding: 14px;
}

.item-image img {
  width: 100%;
  height: 90%;
  border-radius: 20px;
  pointer-events: none;
}

.recomendations {
  font-size: clamp(1em, 1em + 1vw, 1.5em);
}

.tips {
  padding-left: 10px;
  padding-right: 10px;
}

.imgtips img {
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 420px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.containerqrcod {
  max-width: 500px;
  max-height: 500px;
}

.qrcodeimage {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 180px;
}

.yotubetips {
  width: 100%;
  height: 420px;
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.adsstyle {
  max-width: 25%;
  max-height: 25%;
  margin-left: 20px;
  justify-content: flex;
  align-items: flex;
}

.videoInformation {
  width: 100%;
  height: 520px;
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tablecenter {
  justify-content: center;
  align-items: center;
}

.table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  user-select: none;
}

td {
  border: 1px solid #ccc;
  padding: 5px;
}

input[type="text"] {
  border: none;
  box-shadow: inset 0 -1px 0 #ccc;
  font-size: inherit;
  padding: 2px;
  width: 100%;
}

input[type="text"]:focus {
  box-shadow: inset 0 -2px 0 #2196f3;
  outline: none;
}

.btncopy {
  background-color: #000000;
}

.textrecomendations {
  font-size: 40px;
}

.btncopy {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .btncopy {
    font-size: 8px;
  }

  .marginTopTips {
    margin-top: 35%;
  }

  .textrecomendations {
    font-size: 25px;
  }

  .marginTopRecomendations{
    margin-top: 30%;
  }

  .tablecenter table {
    width: auto;
    border-collapse: collapse;
  }
  
  .tablecenter td,
  .tablecenter th {
    word-wrap: break-word;
    border: 1px solid #ddd;
    padding: 8px;
  }
  .tablecenter th {
    background-color: #f2f2f2;
  }
  
}

@media only screen and (min-width: 768px) {
  .marginTopTips {
    margin-top: 10%;
  }

  .marginTopRecomendations{
    margin-top: 7%;
  }
}

.textColorTableRecomendations {
  color: red;
}

input {
  color: #ffffff;
  background-color: #000000;
}

.logoimg{
  buttom: 0;
}

@media only screen and (min-width: 768px){
  .discordLogo{
    buttom: 0;
    margin-top: 10px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 819px){
  #logoimg{
    margin-right: 150px;
    max-width: 250px;
  }

  .discordLogo{
    buttom: 0;
    margin-top: 10px;
    padding-right: 20px;
    margin-left: -25%;
  }

  #discord{
    max-width: 60px;
    max-height: 30px;
  }

  
}